.view__details {
  color: #257fd9;
  background-color: transparent;
  box-shadow: none;
  font-size: 16px;
  height: 40px;
  padding: 6.428571428571429px 15px;
  border: 1px solid #257fd9;
  font-weight: 400;
  .ant-btn-icon {
    margin-inline-end: 8px;
  }
  &:hover {
    color: #4c9ee6;
    border-color: #4c9ee6;
  }
}

.faq__section {
  .custom__collapse {
    .ant-collapse-header {
      //padding: 5px 0 !important;
      .ant-collapse-header-text {
        font-size: 16px !important;
      }
    }

    .ant-collapse-content-box {
      font-size: 14px !important;
    }
    .ant-collapse-item {
      padding: 0 !important;
    }
  }
}
