@import "antd/dist/reset.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); */

/* html {
  font-size: 16px;
} */

@layer base {
  :root {
    /* colors */
    /* #1E7FD9 */
    --color-primary: 30, 127, 217;
    --color-primary-dark: 25, 57, 133;
    --color-primay-2: 25, 57, 133;

    --color-secondary: 40, 91, 209;
    --color-tertiary: 40, 91, 209;
    /* #8BD113 */
    --color-success: 139, 209, 19;
    --color-warning: 209, 191, 19;
    --color-danger: 199, 81, 58;
    /* #D4DEF6 */
    --color-secondary-blue: 212, 222, 246;

    /* Text color */
    /* 1 */
    --color-text-primary: 10, 23, 53;
    /* #193985 */
    --color-text-primary-dark: 25, 57, 133;
    /* #4F46E5 */
    --color-text-secondary: 79, 70, 229;
    /* #686A70 */
    --color-text-secondary-dark: 104, 106, 112;
    --color-text-link: 30, 127, 217;
    --color-text-white: 255, 255, 255;
    --color-text-danger: 225, 61, 29;
    /* #071128 */
    --color-text-primary-black: 7, 17, 40;
    /* #1A1A1A */
    --color-text-primary-black-2: 26, 26, 26;
    /* #333 */
    --color-text-primary-grey: 51, 51, 51;
    /* #999 */
    --color-text-primary-grey-2: 153, 153, 153;
    /* #828282 */
    --color-text-primary-grey-3: 130, 130, 130;
    /* #4F4F4F */
    --color-text-primary-grey-4: 79, 79, 79;
    /* #353940 */
    --color-text-primary-grey-5: 53, 57, 64;
    /* #8B96A5 */
    --color-text-svg: 139, 150, 165;

    /* Background colors */
    /* #F9FBFF */
    --color-background-primary: 249, 251, 255;
    /* #F8F8F8 */
    --color-background-primary-2: 248, 248, 248;
    /* #F8F8F8 */
    --color-background-primary-3: 244, 247, 251;
    /* #F3F7F7 */
    --color-background-primary-4: 243, 247, 247;
    /* #EFF5FB */
    --color-background-secondary: 239, 245, 251;
    /* #EAEFFA */
    --color-background-secondary-blue: 234, 239, 250;
    /* #F0F0F1 */
    --color-background-tertiary: 240, 240, 241;
    /* #189635 */
    --color-background-success: 24, 150, 53;

    /* Border colors */
    /* #98A2B3 */
    --color-border-primary: 152, 162, 179;
    /* #DEE2E7 */
    --color-border-secondary: 222, 226, 231;
    /* #ECECEC */
    --color-border-tertiary: 236, 236, 236;

    /* Font size */
    /* 10px */
    --font-size-xxsm: 0.625rem;
    /* 12px */
    --font-size-xsm: 0.75rem;
    /* 14px */
    --font-size-sm: 0.875rem;
    /* 16px */
    --font-size-md: 1rem;
    /* 18px */
    --font-size-lg: 1.125rem;
    /* 20px */
    --font-size-xl: 1.25rem;
    /* 26px */
    --font-size-2xl: 1.625rem;
    /* 31px */
    --font-size-3xl: 1.9375rem;
    /* 39px */
    --font-size-4xl: 2.4375rem;
    /* 49px */
    --font-size-5xl: 3.0625rem;
    /* 61px */
    --font-size-6xl: 3.8125rem;
    /* 76px */
    --font-size-7xl: 4.75rem;

    /* Line height */
    /* 15px */
    --line-height-xxsm: 0.9375rem;
    /* 18px */
    --line-height-xsm: 1.125rem;
    /* 21px */
    --line-height-sm: 1.3125rem;
    /* 24px */
    --line-height-md: 1.5rem;
    /* 27px */
    --line-height-lg: 1.6875rem;
    /* 32px */
    --line-height-xl: 2rem;
    /* 39px */
    --line-height-2xl: 2.4375rem;
    /* 48px */
    --line-height-3xl: 3rem;
    /* 59px */
    --line-height-4xl: 3.6875rem;
    /* 74px */
    --line-height-5xl: 4.625rem;
    /* 92px */
    --line-height-6xl: 5.75rem;
    /* 114px */
    --line-height-7xl: 7.125rem;

    /* letter spacing */
    --letter-spacing-sm: 0.05em;
    --letter-spacing-md: 0.1em;

    /* Font Weight */
    --font-weight-xxsm: 400;
    --font-weight-xsm: 400;
    --font-weight-sm: 400;
    --font-weight-md: 400;
    --font-weight-lg: 500;
    --font-weight-xl: 400;
    --font-weight-2xl: 400;
    --font-weight-3xl: 400;
    --font-weight-4xl: 600;
    --font-weight-5xl: 400;
    --font-weight-6xl: 400;
    --font-weight-7xl: 400;

    /* Font family */
    --font-family-primary: "Poppins", sans-serif;

    /* Box Shadow */
    --shadow-sm: 0px 8px 21px 0px rgba(0, 0, 0, 0.08);
    --shadow-xs: 0px 4px 12px 0px rgba(51, 51, 51, 0.16);
    --shadow-md: 0px 2px 8px 0px rgba(30, 127, 217, 0.08);
    --shadow-card: 0px 0px 6px 0px #8a8a8a14;
    --max-lines: 2;
  }
}

@media (min-width: 768px) {
  :root {
    --font-weight-lg: 400;
  }
}

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

/* @media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
} */

html {
  /* font-family: var(--font-family-primary); */
  font-family: var(--p-font-family-sans);
  /* overflow-x: hidden; */
}

body {
  font-family: var(--p-font-family-sans);
  /* font-family: var(--font-family-primary); */
  color: rgb(var(--foreground-rgb));
  /* background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb)); */

  background-color: rgba(
    var(--color-background-primary),
    var(--tw-bg-opacity)
  ) !important;
  /* overflow-x: hidden; */
}
main {
  min-height: 45vh;
  margin-top: 111px;
  font-family: var(--p-font-family-sans);
  /* overflow-x: hidden; */
}
.fullpage__layout {
  margin-top: 0;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #e7e7e7;
  border: 1px solid #cacaca;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #686a70;
}

/* Placeholder color */
/* do not group these rules */
*::-webkit-input-placeholder {
  color: rgb(var(--color-text-secondary-dark)) !important;
  font-size: var(--font-size-sm) !important;
  font-weight: 400 !important;
}
*:-moz-placeholder {
  color: rgb(var(--color-text-secondary-dark)) !important;
  font-size: var(--font-size-sm) !important;
  font-weight: 400 !important;
}
*::-moz-placeholder {
  color: rgb(var(--color-text-secondary-dark)) !important;
  font-size: var(--font-size-sm) !important;
  font-weight: 400 !important;
}
*:-ms-input-placeholder {
  color: rgb(var(--color-text-secondary-dark)) !important;
  font-size: var(--font-size-sm) !important;
  font-weight: 400 !important;
}
*::-ms-input-placeholder {
  color: rgb(var(--color-text-secondary-dark)) !important;
  font-size: var(--font-size-sm) !important;
  font-weight: 400 !important;
}
*::placeholder {
  color: rgb(var(--color-text-secondary-dark)) !important;
  font-size: var(--font-size-sm) !important;
  font-weight: 400 !important;
}

/* Ant Design css override */
.ant-form-item {
  margin-bottom: 15px !important;
}
.ant-select-selection-placeholder {
  color: rgb(var(--color-text-secondary-dark)) !important;
  font-size: var(--font-size-sm) !important;
  font-weight: 400 !important;
}
.ant-btn {
  border-radius: 6px !important;
  height: 44px;
}
.ant-input,
.ant-input-affix-wrapper,
.ant-select-selector {
  border-radius: 4px !important;
  border-color: rgb(var(--color-border-primary)) !important;
}
.ant-input {
}
.ant-slider {
  margin-top: 0px !important;
}
.icon__btn {
  display: flex !important;
  align-items: center !important;
  flex-direction: row-reverse !important;
  padding-right: 7px !important
  ;
}
.icon__btn .ant-btn-icon {
  margin: 0 0 0 8px !important;
  margin-inline-end: 0 !important;
}
.icon__btn-left {
  display: flex !important;
  align-items: center !important;
  font-size: 1rem;
  font-weight: 500;
}
.icon__btn-left .ant-btn-icon svg {
  max-width: 1.2rem;
}
.ant-btn-background-ghost {
  background-color: rgb(var(--color-background-secondary)) !important;
}
.ant-radio-inner,
.ant-checkbox-inner {
  height: 1rem !important;
  width: 1rem !important;
  border-color: rgb(var(--color-text-secondary-dark)) !important;
  border-width: 1.5px !important;
}
.ant-radio + span,
.ant-checkbox + span {
  padding-left: 1rem !important;
}

.highcharts-credits {
  display: none;
}

/* sort select */
.sort__by {
  width: auto;
}
.sort__by .ant-select-selector {
  border: none !important;
  background: transparent !important;
  box-shadow: none !important;
  outline: none !important;
}
.sort__by .ant-select-selector .ant-select-selection-item {
  color: rgb(var(--color-text-primary-black)) !important;
}

/* Embed video */
.video__responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video__responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.scrollbar-none {
  scrollbar-width: none;
}

.scrollbar-none::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

/* For WebKit browsers (Chrome, Safari, Edge) */
.thin-scrollbar {
  overflow-y: auto;
}

.thin-scrollbar::-webkit-scrollbar {
  width: 3px; /* Set scrollbar width */
}

.thin-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the scrollbar track */
}

.thin-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Round the corners */
}

.thin-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555; /* Darker color when hovered */
}
/* For Firefox */
.thin-scrollbar {
  scrollbar-width: thin; /* Thin scrollbar */
  scrollbar-color: #888 #f1f1f1; /* Thumb color and track color */
}

/* .root,
#root,
#docs-root {
  --primary-color: #fff;
  --secondary-color: #000;

--body-background: #eef7fb;
--primary-color: #0A9EDC;
--primary-color-dark: #098bc3;
--primary-color-text: #ffffff;

 --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
} */

/* tailwind
"primary-color": "var(--primary-color)", */

/* https://github.com/twbs/bootstrap-sass/blob/master/assets/stylesheets/bootstrap/_variables.scss 
   https://gist.github.com/nzajt/4067486
*/

/* Font size variables */

/* 
  --small: 12px;
  --medium: 16px;
  --large: 24px;

  --text-size-base
  --text-size-large
  --text-size-scaler
  --text-size-huge

—font-size-xxsm: 12px;
—font-size-xsm: 16px;
—font-size-sm: 20px;
—font-size-m: 24px;
—font-size-lg: 28px;
—font-size-xlg: 32px;
—font-size-xxlg: 36px;

$font-size-sm-1: 12px;
$font-size-sm-2: 13px;
$font-size-md-1: 16px;
$font-size-md-2: 18px;
$font-size-lg-1: 20px;
$font-size-lg-2: 24px;

  $small: 12px;
  $form-label: 14px;
  $medium: 16px;
  $large: 24px;

  
--font-family-sans: "Roboto", sans-serif;
--font-family-serif: "Playfair Display", serif;


--font-size-sm: 0.875rem;
--font-size-md: 1rem;
--font-size-lg: 1.125rem;
--font-size-xl: 1.25rem;
--font-size-2xl: 1.5rem;
--font-size-3xl: 2rem;
--font-size-4xl: 3rem;

--line-height-sm: 1.25;
--line-height-md: 1.5;

--letter-spacing-sm: 0.05em;
--letter-spacing-md: 0.1em;

//Responsive typography
:root {
    --font-size-sm: 0.875rem;
    --font-size-md: 1rem;
    --font-size-lg: 1.125rem;
    --font-size-xl: 1.25rem;
    --font-size-2xl: 1.5rem;
    --font-size-3xl: 2rem;
    --font-size-4xl: 2rem;
}

@media (min-width: 56em) {
    :root {
        --font-size-xl: 1.5rem;
        --font-size-2xl: 2rem;
        --font-size-3xl: 2.5rem;
        --font-size-4xl: 3rem;
    }
}
  
*/
/* 75px  */

.container {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

/*  Tablet */
@media only screen and (max-width: 1024px) {
  .container {
    padding-left: 20px !important;
    padding-right: 20px !important;
    max-width: 1024px !important;
  }
  main {
    margin-top: 62px;
  }
}

/* Mobile */
@media only screen and (max-width: 680px) {
  .container {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  main {
    margin-top: 62px;
  }
  body,
  html {
    overflow-x: hidden;
  }
}

.about-us {
  min-height: 20rem;
}
